<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="19"
    viewBox="0 0 25 19">
    <g
      id="Group_88"
      data-name="Group 88"
      transform="translate(-276.027 -160.379)">
      <path
        id="Path_16"
        data-name="Path 16"
        d="M5607.527,2827.879h20"
        transform="translate(-5329 -2658)"
        fill="none"
        stroke="#9ebac9"
        stroke-linecap="round"
        stroke-width="5" />
      <path
        id="Path_18"
        data-name="Path 18"
        d="M5607.527,2827.879h20"
        transform="translate(-5329 -2651)"
        fill="none"
        stroke="#9ebac9"
        stroke-linecap="round"
        stroke-width="5" />
      <path
        id="Path_17"
        data-name="Path 17"
        d="M5607.527,2827.879h20"
        transform="translate(-5329 -2665)"
        fill="none"
        stroke="#9ebac9"
        stroke-linecap="round"
        stroke-width="5" />
    </g>
  </svg>
</template>
