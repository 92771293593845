<template>
	<TransitionRoot
		as="template"
		:show="isSlideOverVisible">
		<Dialog
			class="relative z-10 font-light"
			@close="close">
			<div class="fixed inset-0" />

			<div class="fixed inset-0 overflow-hidden">
				<div
					class="absolute inset-0 overflow-hidden bg-gray-500/35">
					<div
						class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full md:pl-10">
						<TransitionChild
							as="template"
							enter="transform transition ease-in-out duration-500 sm:duration-700"
							enter-from="translate-x-full"
							enter-to="translate-x-0"
							leave="transform transition ease-in-out duration-500 sm:duration-700"
							leave-from="translate-x-0"
							leave-to="translate-x-full">
							<DialogPanel
								class="pointer-events-auto w-screen md:max-w-xl">
								<div
									class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
									<div
										class="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
										<div class="px-4 sm:px-6">
											<div
												class="flex items-start justify-between">
												<DialogTitle
													class="text-2xl leading-6"
													>{{ title }}</DialogTitle
												>
												<div
													class="ml-3 flex h-7 items-center">
													<button
														type="button"
														class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-too-gold ring-offset-2 focus:bg-too-gold/20"
														@click="close">
														<span
															class="absolute -inset-2.5" />
														<span class="sr-only"
															>Close panel</span
														>
														<XMarkIcon
															class="h-6 w-6"
															aria-hidden="true" />
													</button>
												</div>
											</div>
										</div>
										<div
											class="relative flex flex-1 overflow-hidden items-center justify-center w-full h-full">
											<!-- Your content -->
											<AtomImage
												v-if="panelContentType === 'image'"
												:src="panelData.src"
												:alt="panelData.alt"
												type="cover" />

											<AtomVideo
												v-else-if="
													panelContentType === 'video'
												"
												:src="panelData.src"
												:alt="panelData.alt"
												type="cover" />

											<!-- End content -->
										</div>
									</div>
									<div
										class="flex flex-shrink-0 justify-end px-4 bg-gradient-to-tr from-commandes via-iced-copper to-too-gold p-2 pb-4 pr-6 space-x-4">
										<AtomButton
											class="mt-3 w-24 flex-initial text-voir-blue"
											focus="focus:ring-sheffield-grey"
											src="/icons/download.svg"
											label="Download"
											@click="
												downloadAsset(panelData.src)
											" />
									</div>
								</div>
							</DialogPanel>
						</TransitionChild>
					</div>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script setup>
	import {
		Dialog,
		DialogPanel,
		DialogTitle,
		TransitionChild,
		TransitionRoot,
	} from "@headlessui/vue";

	import { XMarkIcon } from "@heroicons/vue/24/outline";
	import { downloadAsset } from "@/utils/actions";

	//Opening mechanism
	const {
		toggleSlideOver,
		isSlideOverVisible,
		panelContentType,
		panelData,
	} = useModal();

	const close = () => {
		toggleSlideOver(false);
	};

	const title = "Content Viewer";
</script>
