<!-- FIX: Should we be getting user data from store or localStorage? Surely from localStorage as that is the currently signed in session? -->
<!-- FIX: Side bar shouldn't fade in and out, it makes it feel like the app acts like a webpage. Only the cards should fade in and out. -->
<template>
	<div>
		<TransitionRoot
			as="template"
			:show="sidebarOpen">
			<Dialog
				as="div"
				class="relative z-40 md:hidden"
				@close="sidebarOpen = false">
				<TransitionChild
					as="template"
					enter="transition-opacity ease-linear duration-300"
					enter-from="opacity-0"
					enter-to="opacity-100"
					leave="transition-opacity ease-linear duration-300"
					leave-from="opacity-100"
					leave-to="opacity-0">
					<div class="fixed inset-0 bg-gray-600/30" />
				</TransitionChild>

				<div class="fixed inset-0 z-40 flex">
					<TransitionChild
						as="template"
						enter="transition ease-in-out duration-300 transform"
						enter-from="-translate-x-full"
						enter-to="translate-x-0"
						leave="transition ease-in-out duration-300 transform"
						leave-from="translate-x-0"
						leave-to="-translate-x-full">
						<DialogPanel
							class="relative flex w-32 max-w-xs flex-1 flex-col bg-counts-black/80 backdrop-blur-sm rounded-tr-2xl rounded-br-2xl">
							<TransitionChild
								as="template"
								enter="ease-in-out duration-300"
								enter-from="opacity-0"
								enter-to="opacity-100"
								leave="ease-in-out duration-300"
								leave-from="opacity-100"
								leave-to="opacity-0">
								<div
									class="absolute top-0 right-0 -mr-12 pt-2">
									<button
										type="button"
										class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-breakwater"
										@click="sidebarOpen = false">
										<span class="sr-only"
											>Close sidebar</span
										>
										<SVGExit />
									</button>
								</div>
							</TransitionChild>
							<div
								class="h-0 flex-1 overflow-y-auto p-6 py-8 space-y-12">
								<AtomLogo />
								<ClientOnly>
									<nav class="mt-5 space-y-4 px-2">
										<template
											v-for="item in navigation"
											:key="item.name">
											<NuxtLink
												v-if="
													(isClient && item.client) ||
													(!isClient && item.admin)
												"
												:to="item.href"
												:class="[
													item.current
														? 'backdrop-blur-sm text-white bg-gradient-to-r from-voir-blue to-transparent'
														: 'text-gray-300 hover:bg-gray-700 hover:text-white',
													'group flex items-center px-2 py-2 text-base font-light rounded-md',
												]">
												<component
													:is="item.icon"
													:class="[
														item.current
															? 'text-gray-300'
															: 'text-gray-400 group-hover:text-gray-300',
														'mr-4 flex-shrink-0 h-8 w-8',
													]"
													aria-hidden="true" />
												{{ item.name }}
											</NuxtLink>
										</template>
									</nav>
								</ClientOnly>
							</div>
							<ClientOnly>
								<div
									class="flex flex-shrink-0 items-center justify-between m-2 p-4 rounded-br-2xl space-x-4 rounded-xl">
									<div class="flex items-center">
										<div>
											<img
												class="inline-block h-10 w-10 rounded-full"
												:src="
													currentUser != null
														? currentUser.profileImage
														: '/icons/basic-fill.svg'
												"
												alt="User profile image" />
										</div>
										<div class="ml-3">
											<p
												class="text-base font-medium text-white">
												{{ userName }}
											</p>
										</div>
									</div>
									<AtomBadge
										v-if="isClient"
										:label="currentUser?.type"
										bgColorProfile="muted" />
								</div>
							</ClientOnly>
						</DialogPanel>
					</TransitionChild>
					<div class="w-14 flex-shrink-0">
						<!-- Force sidebar to shrink to fit close icon -->
					</div>
				</div>
			</Dialog>
		</TransitionRoot>

		<!-- Static sidebar for desktop -->
		<div
			class="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
			<!-- Sidebar component, swap this element with another sidebar if you like -->
			<div
				class="flex min-h-0 flex-1 flex-col bg-counts-black">
				<div
					class="flex flex-1 flex-col overflow-y-auto p-6 pt-12">
					<AtomLogo />

					<!--FIXME: Nav items are still accessible as a client at the moment even if hidden from nav. Need to update auth-->
					<ClientOnly>
						<nav class="mt-12 flex-1 space-y-6 px-2">
							<template
								v-for="item in navigation"
								:key="item.name">
								<NuxtLink
									v-if="
										(isClient && item.client) ||
										(!isClient && item.admin)
									"
									:to="item.href"
									:class="[
										item.current
											? 'bg-gray-900 text-white'
											: 'text-gray-300 hover:bg-gray-700 hover:text-white',
										'group flex items-center px-2 py-2 text-md font-light rounded-md',
										item.name === 'Log Out'
											? 'border-t border-gray-500 rounded-t-none'
											: '',
									]">
									<component
										v-if="item.icon"
										:is="item.icon"
										:class="[
											item.current
												? 'text-gray-300'
												: 'text-gray-400 group-hover:text-gray-300',
											'mr-6 flex-shrink-0 h-8 w-8',
										]"
										aria-hidden="true" />
									{{ item.name }}
								</NuxtLink>
							</template>
						</nav>
					</ClientOnly>
				</div>

				<ClientOnly>
					<MoleculeUserCard />
				</ClientOnly>
			</div>
		</div>

		<!-- Slide over-->
		<OrganismSlideOver />

		<div class="flex flex-1 flex-col md:pl-64 h-screen">
			<!-- Prompt -->
			<!-- <OrganismPrompt /> -->
			<!-- End prompt -->

			<div
				class="sticky top-0 z-10 bg-voir-blue pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
				<button
					type="button"
					class="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-breakwater"
					@click="sidebarOpen = true">
					<span class="sr-only">Open sidebar</span>
					<SVGMenu
						class="h-6 w-6"
						aria-hidden="true" />
				</button>
			</div>
			<!-- Template content slot starts here -->
			<slot></slot>
		</div>
	</div>
</template>

<script setup>
	import {
		Dialog,
		DialogPanel,
		TransitionChild,
		TransitionRoot,
	} from "@headlessui/vue";

	const { isClient, userName, currentUser } = useUser();

	console.log(isClient);

	const navigation = [
		{
			name: "Dashboard",
			href: "/dashboard",
			icon: resolveComponent("SVGDashboard"),
			current: true,
			admin: true,
			client: true,
		},
		{
			name: "Clients",
			href: "/clients",
			icon: resolveComponent("SVGClients"),
			current: false,
			admin: true,
			client: false,
		},
		{
			name: "Profile",
			href: "/profile",
			icon: resolveComponent("SVGClients"),
			current: false,
			admin: false,
			client: true,
		},
		// {
		// 	name: "Staff",
		// 	href: "/staff",
		// 	icon: resolveComponent("SVGStaff"),
		// 	icon: "",
		// 	current: false,
		// 	admin: true,
		// 	client: false,
		// },
		// {
		// 	name: "Posts",
		// 	href: "/posts/1",
		// 	icon: resolveComponent("SVGPosts"),
		// 	current: false,
		// 	admin: true,
		// 	client: true,
		// },
		// {
		// 	name: "Progress",
		// 	href: "/progress",
		// 	icon: resolveComponent("SVGProgress"),
		// 	current: false,
		// 	client: true,
		// 	admin: false,
		// },
		// {
		// 	name: "Pillars",
		// 	href: "/pillars",
		// 	icon: resolveComponent("SVGInsights"),
		// 	current: false,
		// 	client: true,
		// 	admin: false,
		// },
		// {
		// 	name: "Settings",
		// 	href: "/settings",
		// 	icon: resolveComponent("SVGSettings"),
		// 	current: false,
		// 	admin: true,
		// 	client: true,
		// },
		// {
		// 	name: "Admin",
		// 	href: "/admin",
		// 	//icon: AdjustmentsHorizontalIcon,
		// 	current: false,
		// 	admin: true,
		// },
		{
			name: "Log Out",
			href: "/logout",
			icon: null,
			current: false,
			admin: true,
			client: true,
		},
	];

	const sidebarOpen = ref(false);

	//Get current pathname
	const route = useRoute();
	const path = ref(route.path);

	//Update navigation item current to true if on that page
	navigation.forEach(item => {
		if (
			item.href === path.value ||
			path.value.includes(item.href)
		) {
			item.current = true;
		} else {
			item.current = false;
		}
	});
</script>
