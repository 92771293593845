<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="35"
		height="35"
		viewBox="0 0 35 35">
		<g
			id="Group_98"
			data-name="Group 98"
			transform="translate(-5723 -2608)">
			<rect
				id="Rectangle_143"
				data-name="Rectangle 143"
				width="35"
				height="35"
				transform="translate(5723 2608)"
				fill="none" />
			<g
				id="Group_97"
				data-name="Group 97"
				transform="translate(5394.891 2581)">
				<path
					id="Subtraction_14"
					data-name="Subtraction 14"
					d="M16.5,29A16.189,16.189,0,0,1,4.833,24.045,17.27,17.27,0,0,1,1.33,5.418,16.886,16.886,0,0,1,4.95,0a12.477,12.477,0,0,0-.235,2.414A12.162,12.162,0,0,0,8.166,10.96a11.58,11.58,0,0,0,16.667,0,12.162,12.162,0,0,0,3.452-8.545A12.477,12.477,0,0,0,28.05,0a16.885,16.885,0,0,1,3.62,5.418,17.27,17.27,0,0,1-3.5,18.627A16.19,16.19,0,0,1,16.5,29Z"
					transform="translate(329.109 33)"
					fill="#9ebac9" />
				<circle
					id="Ellipse_48"
					data-name="Ellipse 48"
					cx="8.5"
					cy="8.5"
					r="8.5"
					transform="translate(337.109 27)"
					fill="#9ebac9" />
			</g>
		</g>
	</svg>
</template>
