<template>
	<div :class="contentType.container ?? ''">
		<video
			controls
			class="w-full"
			:src="src"
			:alt="alt"
			:type="contentType" />
	</div>
</template>

<script setup>
	const props = defineProps({
		src: {
			type: String,
			default: "/icons/basic-fill.svg",
		},
		alt: {
			type: String,
			default: "Video",
		},
		type: {
			type: String,
			default: "video/mp4",
		},
	});

	const contentType = computed(() => {
		switch (props.type) {
			case "cover":
				return {
					container: "w-full overflow-hidden",
					image: "object-cover w-full h-full",
				};
			case "contain":
				return "object-contain";
			case "fill":
				return "object-fill";
			case "none":
				return "object-none";
			case "scale-down":
				return "object-scale-down";
			default:
				return "w-full";
		}
	});
</script>
