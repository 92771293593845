<template>
	<div
		class="m-2 p-4 space-y-3 rounded-xl bg-breakwater/20 hover:cursor-pointer">
		<div class="flex-shrink-0">
			<div class="flex items-center">
				<div>
					<!-- TODO: Path to UserProfile image won't load on post page due to directory being two deep. Needs changing or addressing in both places in this template (mobile/desktop) -->
					<img
						class="inline-block h-9 w-9 rounded-full"
						:src="
							currentUser != null
								? profile
								: '/icons/basic-fill.svg'
						"
						alt="User profile image" />
				</div>
				<div class="ml-3">
					<p class="text-sm font-medium text-white">
						{{ name }}
					</p>

					<p class="text-xs font-light text-breakwater">
						{{ userType }}
					</p>
				</div>
			</div>
		</div>
		<!-- TODO: Fallback required -->
	</div>
</template>

<script setup>
	const profile = ref("");

	const { isClient, userName, userType, currentUser } =
		useUser();

	if (isClient.value) {
		const user = useSupabaseUser();
		profile.value = computed(
			() =>
				user.value.user_metadata.picture ??
				"/icons/basic-fill.svg"
		);
	} else {
		profile.value = "/icons/basic-fill.svg";
	}

	const name = computed(() => userName.value ?? "You");
</script>
