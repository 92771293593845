<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 35 35">
    <g
      id="dashboard_icon"
      data-name="dashboard icon"
      transform="translate(0 -2705.089)">
      <rect
        id="Rectangle_61"
        data-name="Rectangle 61"
        width="17"
        height="17"
        rx="5"
        transform="translate(0 2705.089)"
        fill="#9ebac9" />
      <rect
        id="Rectangle_61-2"
        data-name="Rectangle 61"
        width="14"
        height="14"
        rx="5"
        transform="translate(21 2705.089)"
        fill="#9ebac9" />
      <rect
        id="Rectangle_61-3"
        data-name="Rectangle 61"
        width="14"
        height="14"
        rx="5"
        transform="translate(0 2726.089)"
        fill="#9ebac9" />
      <rect
        id="Rectangle_61-4"
        data-name="Rectangle 61"
        width="17"
        height="17"
        rx="5"
        transform="translate(18 2723.089)"
        fill="#9ebac9" />
    </g>
  </svg>
</template>
